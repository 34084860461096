function detectOTP() {
    if ('sms' in navigator) {
        return true;
    } else {
        return false;
    }
}

async function receiveOTP() {
    const sms = await navigator.sms.receive();
    return sms.content;
}