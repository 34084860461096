// Give the service worker access to Firebase Messaging.
// Note that you can only use Firebase Messaging here, other Firebase libraries
// are not available in the service worker.
if( 'undefined' === typeof window){
   importScripts('https://www.gstatic.com/firebasejs/11.24.0/firebase-app.js');
 importScripts('https://www.gstatic.com/firebasejs/11.24.0/firebase-messaging.js');
 
 // Initialize the Firebase app in the service worker by passing in the
// messagingSenderId.
firebase.initializeApp({
  apiKey: "AIzaSyBuCt70s9w2aYUhVFoC7aIxl7-ZUiENZbU",
  authDomain: "smartpontos-prod.firebaseapp.com",
  databaseURL: "https://smartpontos-prod.firebaseio.com",
  projectId: "smartpontos-prod",
  storageBucket: "smartpontos-prod.appspot.com",
  messagingSenderId: "794619822389",
  appId: "1:794619822389:web:a2ec4aa0c1f05af217cc7c",
  measurementId: "G-H6PYZFK23W"
});


// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
const messaging = firebase.messaging();

firebase.messaging().setBackgroundMessageHandler(function (payload) {
  // Customize notification here
  var notificationTitle = payload.title;
  var notificationOptions = {
    body: payload.message,
    icon: '/images/icons/icon-96x96.png'
  };

  return self.registration.showNotification(notificationTitle,
    notificationOptions);
  });

}